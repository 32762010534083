<template>
  <v-card elevation="12" min-height="600">
    <v-card-title>Rfid Manager</v-card-title>

    <v-btn
      v-if="!readerConnected"
      class="ma-2"
      outlined
      color="primary"
      @click="activateReader"
    >
      Connect the RFID reader
    </v-btn>
    <v-container v-if="readerConnected">
      <v-card-text>
        Device name: <strong> {{ deviceName }} </strong>
        <br />
        Last rfid tag read:
        <strong> {{ tagRead }} </strong>
        <br />
        RSSI: <strong> {{ tagReadRSSI }} dBm </strong>
        <br />
        <br />
        Last QR code read:
        <strong> {{ qrRead }} </strong>
        <v-switch v-model="rfid_switch" :label="'RFID Module'"></v-switch>
        <v-switch v-model="qr_switch" :label="`QRCODE Module`"></v-switch>
        <v-switch
          v-model="trigger_switch"
          :label="`TRIGGER Status`"
          color="success"
          disabled
        ></v-switch>
        <!-- <v-btn class="ma-2" outlined color="success" @click="singleRead">
          Single-read
        </v-btn>
        <br />
        <v-btn class="ma-2" outlined color="success" @click="multiRead">
          Multi-read
        </v-btn>
        <v-btn class="ma-2" outlined color="primary" @click="stopMultiRead">
          Stop Multi-read
        </v-btn> -->

        <br />
        <v-btn
          v-if="readerConnected"
          class="ma-2"
          outlined
          color="error"
          @click="disconnect"
        >
          Disconnect the reader
        </v-btn>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import Vue from "vue";
// @ is an alias to /src
import RFID_Reader from "../../utils/rfid_lib/rfid_reader.js";

// Read sound
import { useSound } from "@vueuse/sound";
import readSfxWav from "../../assets/read_code.wav";

export default {
  name: "RfidManager",

  setup() {
    const { play } = useSound(readSfxWav);

    return {
      play,
    };
  },
  data: () => ({
    //RFID: null,
    readerConnected: false,
    deviceName: null,
    rfid_switch: false,
    qr_switch: false,
    trigger_switch: false,
    tagRead: null,
    tagReadRSSI: null,
    qrRead: null,
  }),
  watch: {
    rfid_switch(newValue) {
      //called whenever rfid_switch changes
      if (newValue) {
        // true -> start module
        this.$rfid.startRFIDReader();
      } else {
        // false -> stop module
        this.$rfid.stopRFIDReader();
      }
    },
    qr_switch(newValue) {
      //called whenever qr_switch changes
      if (newValue) {
        // true -> start module
        this.$rfid.startQRReader();
      } else {
        // false -> stop module
        this.$rfid.stopQRReader();
      }
    },
  },
  methods: {
    activateReader() {
      console.log("Activate the reader");

      console.log(this.$rfid);
      if (!this.$rfid) {
        console.log(
          "RFID reader class not instantiated... going to instantiate it!"
        );
        //this.RFID = new RFID_Reader();

        // Assign the instance of rfid reader to the Vue prototype, this way it is accessible to all Vue components
        Vue.prototype.$rfid = new RFID_Reader();

        console.log(this.$rfid);

        // Run th discover method passing the callback that will be called as soon as the device will be disconnected
        this.$rfid
          .discover(this.onDeviceDisconnected)
          .then((response) => {
            // Device found we are now connected let's set the notify and disconnect methods
            console.log(response);
            this.readerConnected = true;
            this.deviceName = this.$rfid.name;

            // Set the callback to notify new readings by the reader
            this.$rfid.startNotification(this.handleNotification);
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },

    disconnect() {
      console.log("Disconnect the reader");
      this.$rfid.disconnect();
    },

    handleNotification(notification) {
      // // Migrazione di questa parte nella lib reader, qui mi deve già arrivare la notifica di evento pulita...
      // // qui riceviamo un noticeframe o un responseframe
      // let array = new Uint8Array(event.target.value.buffer);
      // //console.log("Handled notification: " + array);
      // //console.log(array);

      // let notification = this.$rfid.parseNotificationArrayBuffer(array);

      // We parsed a valid notification packet
      if (notification && notification.type && notification.value !== null) {
        switch (notification.type) {
          case "trigger":
            //console.log(notification.value);
            this.trigger_switch = notification.value;
            break;

          case "qrcode":
            console.log(notification.value);
            this.qrRead = notification.value;
            // Play sound after a code was red
            this.play();
            break;

          case "rfid":
            if (notification.value.EPC != 0) {
              this.tagRead = notification.value.EPC;
              this.tagReadRSSI = notification.value.RSSI;
              // Play sound after a code was red
              this.play();
            }
        }
      }
      // console.log("before new read: " + this.tagRead);

      // if (response.command == "rfid_read") {
      //   if (response.tag && response.tag != "0x") {
      //     this.tagRead = response.tag;
      //     this.tagReadRSSI = response.RSSI;
      //     console.log("tag red: " + this.tagRead);
      //   }
      // } else {
      //   this.tagRead = "Error reading...";
      // }
    },

    onDeviceDisconnected() {
      this.status = "Disconnected";
      console.log("Device was disconnected...");
      this.readerConnected = false;

      this.$rfid.removeNotificationCallback(this.handleNotification);
      this.$rfid.removeDisconnectedCallback(this.onDeviceDisconnected);

      Vue.prototype.$rfid = null;
      //this.deviceName = null;
      //this.tagRead = null;
      //this.tagReadRSSI = null;
    },
  },

  created: function () {
    //console.log(this.$rfid);
    if (this.$rfid && this.$rfid.status === "Connected") {
      this.readerConnected = true;

      this.$rfid.setNotificationCallback(this.handleNotification);
      this.$rfid.setDisconnectedCallback(this.onDeviceDisconnected);
    }
    //console.log('mounted');
  },

  beforeDestroy: function () {
    if (this.$rfid) {
      this.$rfid.removeNotificationCallback(this.handleNotification);
      this.$rfid.removeDisconnectedCallback(this.onDeviceDisconnected);
    }
  },
};
</script>