// REMEMBER that this works only with tls https
// serve with a valid certificate
// for localhost generate a certificate then:
// http-server --ssl --cert ~/.localhost-ssl/localhost.crt --key ~/.localhost-ssl/localhost.key

// // In base al pacchetto da comporre valutarne la size
// // Dichiarare un array buffer[size] da riempire con i dati del pacchetto

// // FORMATO DEL PACCHETTO DA INVIARE:
// // |_HEADER_|__LEN___|_ADDRESS_|__CMD___|_CMD_DATA_|_CHECKSUM_|
// // |_1 byte_|_1 byte_|_1 byte__|_1 byte_|__n byte__|__1 byte__|
// // |___0____|___1____|____2____|___3____|_4->4+n-1_|LEN + 2 -1|

// // LEN: si conta dal terzo byte fino alla fine -> vengono esclusi quindi dal conteggio i due byte di header e len
// // CHECKSUM: viene valutato su tutti i byte escluso il checksum stesso

// // Esempio pacchetto attivazione modulo rfid e qrcode
// // Passo a metodo hexStringToArrayBuffer il pacchetto completo che voglio spedire settando ultimo esadecimale a 0,
// // il metodo fillChecksum si occupa successivamente di calcolare e modificare il valore dell'ultimo esadecimale

// Enum definition for the possible command of the reader packets
const READER_CMD = {
  rfid: 137,
  qrcode: 250,
  trigger: 252,
};

export default class RFID_Reader {


  constructor() {

    // console.log("Reader Constructor Invoked");

    // The bluetooth device retrieved
    this.myDevice = null;

    // The BLE Service and Characteristics used (notify and write), to configure the device and obtain data
    this.primaryService = null;
    this.writeCharacteristic = null;
    this.notifyCharacteristic = null;

    this.name = null;
    this.status = null;
    this.isConnected = false;

    this.triggerPulled = false;

    // Some barcode/qrcode/rfid? requires multiple packet to complete reading, so we need to process them togheter

    // If full data is splitted into several frames, we keep partial packet left here to concatenate with the next received one
    this.partialPacket = new Uint8Array();

    this.packetRFIDBuffer = [];
    this.packetQrBuffer = [];
    this.packetTriggerBuffer = [];

    this.commandContext = null;

    this.textEncoder = new TextEncoder(); // always utf-8
    this.textDecoder = new TextDecoder(); //.decode(uint8array);

    this.notifyCallback = null;
    this.disconnectedCallback = null;
  }


  // Methods
  discover() {
    // console.log("Discover the BLE reader");


    return new Promise((resolve, reject) => {
      if (!navigator.bluetooth) {
        reject('Bluetooth device unavailable');
        return
      }
      // Call navigator.bluetooth.requestDevice
      navigator.bluetooth.requestDevice({
        filters: [
          // { services: ['0000fff0-0000-1000-8000-00805f9b34fb'] },
          { name: 'Minew_V5.34H' },
        ],
        //acceptAllDevices: true,
        optionalServices: [0xFFF0] // Required to access service later.
      })
        .then(device => {
          this.myDevice = device;
          // console.log(device);
          this.name = device.name;

          // Set up event listener for when device gets disconnected.
          // device.addEventListener('gattserverdisconnected', () => { this.isConnected = false; disconnectCallback() });

          // Attempts to connect to remote GATT Server.
          return device.gatt.connect();
        })
        .then(server => {
          this.status = "Connected";
          this.isConnected = true;
          return server.getPrimaryService('0000fff0-0000-1000-8000-00805f9b34fb');
        })
        .then(pService => {
          this.primaryService = pService;
          // console.log(this.primaryService);

          // Get notify characteristic
          return this.primaryService.getCharacteristic('0000fff1-0000-1000-8000-00805f9b34fb');
        })
        .then(nCharact => {
          this.notifyCharacteristic = nCharact;
          // console.log(this.notifyCharacteristic);

          // Get write characteristic
          return this.primaryService.getCharacteristic('0000fff2-0000-1000-8000-00805f9b34fb');
        })
        .then(wCharact => {
          this.writeCharacteristic = wCharact;
          // console.log(this.writeCharacteristic);

          // We need to send the t-30 password before 5 sec to avoid disconnection
          let t30password = this.textEncoder.encode("12345678");
          // console.log(t30password);

          return this.writeCharacteristic.writeValue(t30password)
        })
        .then(() => {
          // console.log('Password sent to t-30 to avoid auto-disconnect after 5 sec.');
          //console.log(result);
          resolve('Device connected and ready to operate!')
        })
        .catch(error => {
          console.log(error);
          reject('Device connection error!!!')
        });
    });
  }

  disconnect() {
    // console.log('Disconnetting device...');
    this.isConnected = false;
    this.myDevice.gatt.disconnect();
  }


  // Activate BARCODE QRCODE reader
  startQRReader() {

    // Activate openCloseModule command:
    let turnOnBarcode = this.fillChecksum(this.hexStringToArrayBuffer("0xA006FFF902010100"));

    this.writeCharacteristic.writeValue(turnOnBarcode)
      .then(() => {
        // console.log('Command to start barcode and qrcode module sent.');
      })
      .catch(error => { console.error(error); });
  }

  // Activate RFID reader
  startRFIDReader() {
    // Activate openCloseModule command:
    let turnOnRfid = this.fillChecksum(this.hexStringToArrayBuffer("0xA006FFF901010100"));
    this.writeCharacteristic.writeValue(turnOnRfid)
      .then(() => {
        // console.log('Command to start rfid module sent.');
      })
      .catch(error => { console.error(error); });
  }

  // Activate BARCODE QRCODE reader
  stopQRReader() {
    // Deactivate openCloseModule command:
    let turnOffBarcode = this.fillChecksum(this.hexStringToArrayBuffer("0xA006FFF902000000"));
    this.writeCharacteristic.writeValue(turnOffBarcode)
      .then(() => {
        // console.log('Command to stop barcode and qrcode module sent.');
      })
      .catch(error => { console.error(error); });
  }

  // Activate RFID reader
  stopRFIDReader() {
    // Deactivate openCloseModule command:
    let turnOffRfid = this.fillChecksum(this.hexStringToArrayBuffer("0xA006FFF901000000"));
    this.writeCharacteristic.writeValue(turnOffRfid)
      .then(() => {
        // console.log('Command to stop rfid module sent.');
      })
      .catch(error => { console.error(error); });
  }


  // Associate notification callback
  startNotification(callback) {
    // Activate notification command:
    this.notifyCharacteristic.startNotifications()
      .then(characteristic => {
        characteristic.addEventListener('characteristicvaluechanged', this.parseNotificationArrayBuffer.bind(this));  // .bind(this) is required, if not callback "this" refers to a bluetoothGATT  
        this.notifyCallback = callback;
        // console.log('Command notification ON sent.');
      })
      .catch(error => { console.error(error); });
  }

  removeNotificationCallback(callback) {
    this.notifyCharacteristic.removeEventListener('characteristicvaluechanged', callback);
  }
  onDisconnected() {
    this.disconnect();
    this.disconnectedCallback();
  }

  setDisconnectedCallback(callback) {
    this.myDevice.addEventListener('gattserverdisconnected', this.onDisconnected);
    this.disconnectedCallback = callback;
  }

  removeDisconnectedCallback(callback) {
    this.myDevice.removeEventListener('gattserverdisconnected', callback);
    this.disconnectedCallback = null;

  }

  // Parse notification received from the reader
  parseNotificationArrayBuffer(event) {



    let arrayBuffer = new Uint8Array(event.target.value.buffer);
    // console.log(arrayBuffer);

    // // Migrazione di questa parte nella lib reader, qui mi deve già arrivare la notifica di evento pulita...
    //   // qui riceviamo un noticeframe o un responseframe
    //   let array = new Uint8Array(event.target.value.buffer);
    //   //console.log("Handled notification: " + array);
    //   //console.log(array);

    //   let notification = this.$rfid.parseNotificationArrayBuffer(array);

    // FORMATO DEL PACCHETTO:
    // |_HEADER_|__LEN___|_ADDRESS_|__CMD___|_CMD_DATA_|_CHECKSUM_|
    // |_1 byte_|_1 byte_|_1 byte__|_1 byte_|__n byte__|__1 byte__|
    // |___0____|___1____|____2____|___3____|_4->4+n-1_|LEN + 2 -1|

    // Algoritmo usato per valutare più frame bluetooth da concatenare:
    // Arrivata notifica di pacchettone(frame) bluetooth ricevuto, il frame può contenere o un singolo packet spedito dal lettore o più packets
    // Esempio trigger premuto/rilasciato, barcode e qrcode corti e rfid: per ora ogni evento di questi genera un pacchetto che è singolarmente analizzabile.
    // Caso di un qrcode lungo (molti dati contenuti) in questo caso il pacchettone contiene uno di seguito all'altro i vari pacchetti, ogni pacchetto contiene un byte del codice letto, ultimo pacchetto contiene un char enter -> 13 -> 0D
    // A notifica ricevuta:
    // Se ho un avanzo di byte residui da analisi frame precedente allora creo un nuovo frame mettendo in testa avanzo + frame notificato altrimenti analizzo direttamente il frame notificato
    // Valutiamo il primo byte del frame (creato o notificato), tutti i packet devono iniziare con 0XA0
    // Cerco indice del byte 0XA0 se coincide con 0 allora ok procedo altrimenti c'è sicuramente un errore, incremento contatore che conta errori ricezione di uno, poi chi usa deciderà che farne
    // Leggo poi il byte subito successivo che contiene la LEN del mio packet
    // Se ci sono abbastanza byte nel frame allora faccio una copia del packet per poi controllarlo e mi annoto l'indice in cui dovrei trovare nuovamente un 0xA0 per estrazione pacchetto successivo
    //   -> Analisi pacchetto viene eseguita verificando prima il checksum e poi estrapolando il contesto (barcode) e estrapolando i dati contenuti nel pacchetto (1 char) che aggiungo al mio dataBuffer
    //   -> se checksum ok estraggo dati, altrimenti incremento errore pacchetto
    // Se non ci sono abbastnza byte nel frame per ottenere un packet intero memorizzo la parte di packet troncata e la rincollerò in testa al successivo frame una volta notificato
    // Guardo contenuto del frame all'indice segnato in precedenza dove dovrei trovare un A0 se ok procedo altrimenti cerco nuovo indice A0 e incremento errore

    let currentFrame = null;

    // Test to verify if the first ruined part of a packet is discarded
    //this.partialPacket = new Uint8Array([10, 20, 30, 40, 50]);

    // Packet header
    const header = 160;     // header (0xA0 -> 160)


    // Aggiungo il pacchetto avanzato solo se il pacchetto corrente non inizia con 0xA0 -> 160, questo perchè il lettore fa interleave tra pacchetti qr e rfid, quindi magari avanza un pezzetto che però non va concatenato a quello subito successivo, ma al primo che non inizia con A0
    if (this.partialPacket.byteLength > 0 && arrayBuffer[0] != header) {

      // console.log("partial:");
      // console.log(this.partialPacket);

      // Ho un avanzo da analisi frame precedente creo un nuovo frame mettendo avanzo in testa
      // console.log("Aggiungo pezzo di packet avanzato all'inizio del current frame")
      // console.log(this.partialPacket.byteLength);
      currentFrame = this.appendBuffer(this.partialPacket, arrayBuffer);

      // console.log(currentFrame);
      // Resetto il partial packet
      this.partialPacket = new Uint8Array();
    }
    else {
      // Nessun avanzo presente, analizzo direttamente il frame notificato
      currentFrame = arrayBuffer;
    }

    // Parse all the packet in the frame
    let frameExausted = false;
    let nextIndex = 0;

    let stop = 0;
    while (!frameExausted && stop < 500) {
      stop++;
      // console.log(stop);
      // Cerco indice dell'header
      let headerIndex = currentFrame.indexOf(header, nextIndex);
      //console.log("header index:" + headerIndex);

      // La len si trova nel byte dopo l'header, dalla len sono sempre esclusi header e len quindi per avere lunghezza complessiva sommo 2
      let packetLen = currentFrame[headerIndex + 1] + 2;
      //console.log("packet len:" + packetLen);

      let packet = currentFrame.slice(headerIndex, headerIndex + packetLen);
      //console.log("packet:" + packet);

      if (this.validateChecksum(packet)) {

        // Pacchetto trovato e validato, lo aggiungo al packet buffer per analisi di contesto
        // Packet is valid
        this.commandContext = packet[3];
        //console.log(this.commandContext);

        switch (this.commandContext) {

          // Command 137 -> 0x89 an rfid tag was red
          case READER_CMD.rfid:
            this.packetRFIDBuffer.push(packet);
            break;

          // Command 250 -> 0xFA a bar/qr code was red
          case READER_CMD.qrcode:
            this.packetQrBuffer.push(packet);
            break;

          // Command 252 -> trigger pulled or not, depending by the data in the packet
          case READER_CMD.trigger:
            this.packetTriggerBuffer.push(packet);
            break;

          default:
            return;
        }
      }
      // else {
      //   console.log("Invalid packet found");
      //   console.log(packet);
      //   frameExausted = true;
      // }

      // Check if the 0xA0 is the last byte and len is not available or if partial packet
      if (headerIndex == currentFrame.byteLength - 1 || headerIndex + packetLen > currentFrame.byteLength) {
        //Frame o frames consumati completamente
        // console.log("Headerindex: " + headerIndex);
        // console.log("Len: " + packetLen);
        // console.log("FrameLen: " + currentFrame.byteLength);

        //ma ultimo packet incompleto, lo aggiungo al prossimo frame che arriverà... slice con solo un parametro copia da li alla fine
        this.partialPacket = currentFrame.slice(headerIndex);
        frameExausted = true;
        nextIndex = 0;
      }
      // Check if packets fullfill exactly the frame
      else if (headerIndex + packetLen == currentFrame.byteLength) {
        //Frame o frames consumati completamente
        // console.log("Headerindex: " + headerIndex);
        // console.log("Len: " + packetLen);
        // console.log("FrameLen: " + currentFrame.byteLength);
        frameExausted = true;
        nextIndex = 0;
      }
      // else if (headerIndex + packetLen > currentFrame.byteLength) {

      //   console.log("Headerindex: " + headerIndex);
      //   console.log("Len: " + packetLen);
      //   console.log("FrameLen: " + currentFrame.byteLength);

      //   //ma ultimo packet incompleto, lo aggiungo al prossimo frame che arriverà... slice con solo un parametro copia da li alla fine
      //   this.partialPacket = currentFrame.slice(headerIndex);
      //   frameExausted = true;
      //   nextIndex = 0;
      // }
      else {
        // Frame non ancora consumato completamente, verifico nuovo indice header
        // console.log("Headerindex: " + headerIndex);
        // console.log("Len: " + packetLen);
        // console.log("FrameLen: " + currentFrame.byteLength);
        nextIndex = headerIndex + packetLen;
      }
    }

    //console.log(this.partialPacket.byteLength);
    // Per pacchetti non qrcode per ora basta vedere che non ci sia avanzo.
    // Per qrcode serve anche valutare il char 13 enter che denota fine di un qrcode
    if (this.partialPacket.byteLength == 0) {
      //let data = null;

      if (this.packetRFIDBuffer.length > 0) {
        // Ora di analizzare il contenuto del o dei pacchetti
        //data = this.extractData(this.packetRFIDBuffer);
        this.dispatchNotification(this.readRFID(this.packetRFIDBuffer[0]));
        this.packetRFIDBuffer = [];
      }
      if (this.packetQrBuffer.length > 0) {
        if (this.packetQrBuffer[this.packetQrBuffer.length - 1][4] == 13) {
          // console.log(this.packetQrBuffer);
          // Ora di analizzare il contenuto del o dei pacchetti
          //data = this.extractData(this.packetQrBuffer);
          this.dispatchNotification(this.readQRcode(this.packetQrBuffer));
          this.packetQrBuffer = [];
        }
      }
      if (this.packetTriggerBuffer > 0) {

        // Ora di analizzare il contenuto del o dei pacchetti
        //data = this.extractData(this.packetTriggerBuffer);
        this.dispatchNotification(this.readTrigger(this.packetTriggerBuffer[0]));
        this.packetTriggerBuffer = [];
      }

      this.commandContext = null;
      // switch (this.commandContext) {
      //   // Command 137 -> 0x89 an rfid tag was red
      //   case READER_CMD.rfid:
      //     // Ora di analizzare il contenuto del o dei pacchetti
      //     data = this.extractData(this.packetRFIDBuffer);
      //     this.dispatchNotification(data);
      //     this.packetRFIDBuffer = [];
      //     // Reset the last command context
      //     this.commandContext = null;
      //     console.log(data);
      //     break;

      //   // Command 250 -> 0xFA a bar/qr code was red
      //   case READER_CMD.qrcode:
      //     //let lastQRcodeChar = this.packetBuffer[this.packetBuffer.length - 1][4];
      //     if (this.packetQrBuffer[this.packetQrBuffer.length - 1][4] == 13) {
      //       console.log(this.packetQrBuffer);
      //       // Ora di analizzare il contenuto del o dei pacchetti
      //       data = this.extractData(this.packetQrBuffer);
      //       this.dispatchNotification(data);
      //       this.packetQrBuffer = [];
      //       // Reset the last command context
      //       this.commandContext = null;
      //       console.log(data);
      //     }
      //     break;

      //   // Command 252 -> trigger pulled or not, depending by the data in the packet
      //   case READER_CMD.trigger:
      //     // Ora di analizzare il contenuto del o dei pacchetti
      //     data = this.extractData(this.packetTriggerBuffer);
      //     this.dispatchNotification(data);
      //     this.packetTriggerBuffer = [];
      //     // Reset the last command context
      //     this.commandContext = null;
      //     console.log(data);
      //     break;
      // }
    }
  }

  // extractData(packets) {

  //   let extractedData = null;
  //   //console.log(this.commandContext);
  //   //console.log(this.packetBuffer);

  //   //console.log("dispatch to: ");
  //   //console.log(this.commandContext);

  //   switch (this.commandContext) {

  //     // Command 137 -> 0x89 an rfid tag was red
  //     case READER_CMD.rfid:
  //       extractedData = this.readRFID(packets[0]);
  //       break;

  //     // Command 250 -> 0xFA a bar/qr code was red
  //     case READER_CMD.qrcode:
  //       extractedData = this.readQRcode(packets);
  //       break;

  //     // Command 252 -> trigger pulled or not, depending by the data in the packet
  //     case READER_CMD.trigger:
  //       extractedData = this.readTrigger(packets[0]);
  //       break;
  //   }

  //   return extractedData;

  // }

  dispatchNotification(data) {

    //console.log(extractedData);
    this.notifyCallback(data);
  }

  // Obtain the trigger status (pulled or not)
  readTrigger(packet) {

    let response = null;
    let triggerPulled = null;

    if (packet[4] == 1) {
      triggerPulled = false;
    }
    else {
      triggerPulled = true;
    }
    response = {
      type: "trigger",
      value: triggerPulled     // Set trigger pulled or not
    }
    return response;
  }

  // Obtain the bar/qr code from the arraybuffer
  readQRcode(packets) {

    //console.log(packets);
    let response = null;

    let qrCode = [];
    for (let p of packets) {
      qrCode.push(p[4]);
    }

    response = {
      type: "qrcode",
      value: (this.textDecoder.decode(new Uint8Array(qrCode)))//.replace(/[^a-zA-Z0-9 -:]/g, "")     // CONVERT THE UINT8ARRAY into char string cleaning the string from special char
    }

    return response;
  }

  // Obtain the rfid data from the arraybuffer
  // FreqAnt -> The high 6 bits are frequency parameter; the low 2 bits are antenna ID.
  // PC   -> The Protocol Control bits provides the length of the EPC stored in the tag
  // EPC  -> Electronic product code (up to 96 bit)
  // RSSI -> Received Signal Strength Indication

  // FORMATO DEL PACCHETTO RICEVUTO PER OGNI SINGOLO RFID LETTO:
  // |_HEADER_|__LEN___|_ADDRESS_|__CMD___|_FreqAnt_|___PC___|___EPC____|____RSSI______|_CHECKSUM_|
  // |_1 byte_|_1 byte_|_1 byte__|_1 byte_|__1 byte_|_2 byte_|_n byte___|___1 byte_____|__1 byte__|
  // |___0____|___1____|____2____|___3____|____4____|__5-6___|__________|______________|LEN + 2 -1|

  readRFID(packet) {

    let response = {};
    let rfidPacket = packet;
    let rfid_data = {};


    let LEN = null;
    let FreqAnt = null;   // 1 byte
    let PC = [];          // 2 byte
    let EPC = [];         // n byte
    let RSSI = null;      // 1 byte

    // The packet was received correctly
    LEN = rfidPacket[1];
    FreqAnt = rfidPacket[4];
    PC = rfidPacket.slice(5, 6);
    EPC = rfidPacket.slice(7, LEN);
    RSSI = rfidPacket.slice(LEN, LEN + 1);


    rfid_data = {
      "FreqAnt": FreqAnt,
      "PC": this.buf2hex(PC),
      "EPC": this.buf2hex(EPC),//this.textDecoder.decode(EPC),
      //"RSSI": this.byte2Int(RSSI)
      "RSSI": (RSSI - 128)
    }

    response = {
      type: "rfid",
      value: rfid_data
    }

    return response;
  }



  /* Utils */

  // Convert a hex string to a byte array
  hexStringToArrayBuffer(hexString) {
    // remove the leading 0x
    hexString = hexString.replace(/^0x/, '');

    // ensure even number of characters
    if (hexString.length % 2 != 0) {
      console.log('WARNING: expecting an even number of characters in the hexString');
    }

    // check for some non-hex characters
    var bad = hexString.match(/[G-Z\s]/i);
    if (bad) {
      console.log('WARNING: found non-hex characters', bad);
    }

    // split the string into pairs of octets
    var pairs = hexString.match(/[\dA-F]{2}/gi);

    // convert the octets to integers
    var integers = pairs.map(function (s) {
      return parseInt(s, 16);
    });

    var array = new Uint8Array(integers);

    return array;
  }

  // Convert from baseA to baseB
  // Note: Both baseA and baseB must be between 2 and 36.
  convertNumToBase(num, baseA, baseB) {
    if (!(baseA < 2 || baseB < 2 || isNaN(baseA)
      || isNaN(baseB) || baseA > 36 || baseB > 36)) {
      return parseInt(num, baseA).toString(baseB);
    }
  }

  // Evaluate packet checksum exluding the last byte (that represents the checksum value)
  evaluateChecksum(packet) {
    // SUM all the byte exluding the last one (the checksum)
    let sum = 0;
    for (let i = 0; i < packet.byteLength - 1; i++) {
      sum += packet[i];
    }

    // Take the less significant (most right) char
    return ((~sum + 1) & 0xFF);
  }

  // Fill packet checksum
  fillChecksum(packet) {
    // Take the less significant (most right) char
    packet[packet.byteLength - 1] = this.evaluateChecksum(packet);
    return packet;
  }

  // Validate packet evaluating and checking its checksum
  // Fill packet checksum
  validateChecksum(packet) {
    // Take the less significant (most right) char
    if (packet[packet.byteLength - 1] === this.evaluateChecksum(packet)) {
      return true;
    }
    else {

      return false;
    }


  }

  // Unpack massive array packets in an array of packets, splitting by header (0xA0 -> 160)
  // The reader when read a barcode/qrcode return this type of uint8array
  unpack(response, header) {

    let packets = [];

    // Find indexes of headers and size of each packet, store them in a dictionary
    for (let b = 0; b < response.byteLength; b++) {
      if (response[b] === header) {
        // Take the size of this packet
        let packetLen = response[b + 1] + 2;
        // Extract the packet with slice and add to the packets array
        packets.push(response.slice(b, b + packetLen));
      }
    }
    return packets;
  }


  /**
  * Creates a new Uint8Array based on two different ArrayBuffers
  *
  * @private
  * @param {ArrayBuffers} buffer1 The first buffer.
  * @param {ArrayBuffers} buffer2 The second buffer.
  * @return {ArrayBuffers} The new ArrayBuffer created out of the two.
  */
  appendBuffer(buffer1, buffer2) {
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(buffer1, 0);
    tmp.set(buffer2, buffer1.byteLength);
    return tmp;//.buffer;
  }


  // Accept an array buffer of Uint8 and produce a string with its hexadecimal values
  buf2hex(arrayBuffer) { // buffer is an ArrayBuffer
    return '0x' + [...arrayBuffer]
      .map(x => x.toString(16).padStart(2, '0'))
      .join('');
  }

  // Convert a byte into int (used to convert the RSSI byte into dBm)
  byte2Int(byte) {
    return byte.charCodeAt(0)
  }
}